//import jwtDecode from "jwt-decode";

// const initialState = {
//     best_selling_products: [],
//     menu: [],
//     new_products: [],
//     sections: [],
//     special_offer_products: [],
//     sliders: []
// }
export const ui = (state = {}, action) => {
    switch (action.type) {
        case "UI": {
            return {
                category_parent: action.payload.category_parent,
                best_selling_products: action.payload.best_selling_products,
                brands : action.payload.brands ,
                contact_us: action.payload.contact_us,
                menu: action.payload.menu,
                new_products: action.payload.new_products,
                sections: action.payload.sections,
                special_offer_products: action.payload.special_offer_products,
                sliders: action.payload.sliders,
                articles: action.payload.articles,
                story: action.payload.story,
                diesel_generator: action.payload.diesel_generator,
                alternator: action.payload.alternator,
                special_products: action.payload.special_products,
                faq :action.payload.faqs,
                how_to_buy :action.payload.how_to_buy_content,
                how_to_send :action.payload.how_to_send_content,
                privacy_content :action.payload.privacy_content,
                expert_advice_content :action.payload.expert_advice_content,
                home_title :action.payload.home_title,
                home_content :action.payload.home_content,
            }
        }
        default: {
            return state
        }
    }
}
